<template>
  <div>
    <div class="card shadow">
      <div class="pt-4 pb-4 pe-4 ps-4" @keyup.enter="$emit('getRaasList')">
        <div class="row pb-4">
          <div class="col-sm-4 filter">
            <div class="dp__input_wrap text-gray-600">
              <input
                name="Denominazione"
                type="text"
                placeholder="Denominazione"
                class="form-control"
                :value="denominazioneAffiliazioniRaas"
                @input="setDenominazioneAffiliazioniRaas($event.target.value)"
              />
            </div>
          </div>
          <div class="col-sm-4 filter">
            <div class="dp__input_wrap text-gray-600">
              <input
                name="cod_fiscale"
                type="text"
                placeholder="Codice fiscale"
                class="form-control"
                :value="codiceFiscAffiliazioniRaas"
                @input="setCodiceFiscAffiliazioniRaas($event.target.value)"
              />
            </div>
          </div>
          <div class="col-sm-4 filter">
            <div class="dp__input_wrap text-gray-600">
              <input
                name="cod_affiliazione"
                type="text"
                placeholder="Codice affiliazione"
                class="form-control"
                :value="codiceAffAffiliazioniRaas"
                @input="setCodiceAffAffiliazioniRaas($event.target.value)"
              />
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-3 filter">
            <SelectInput
              placeholder="Comitato"
              :options="comitati"
              name="id_comitato"
              :value="comitatoAffiliazioniRaas"
              @changeSelect="setComitatoAffiliazioniRaas"
            />
          </div>
          <div class="col-sm-2 align-self-center text-center">
            <input
              class="form-check-input"
              type="checkbox"
              :checked="stati_invio && stati_invio.includes('1')"
              @click="setDaInviareAffiliazioniRaas()"
            />
            <label class="fw-bold ps-1 text-gray-800"> Da inviare</label>
          </div>
          <div class="col-sm-2 align-self-center text-center">
            <input
              class="form-check-input"
              type="checkbox"
              :checked="stati_invio && stati_invio.includes('2')"
              @click="setInviateAffiliazioniRaas()"
            />
            <label class="fw-bold ps-1 text-gray-800"> Corr. Inviata</label>
          </div>
          <div class="col-sm-2 align-self-center text-center">
            <input
              class="form-check-input"
              type="checkbox"
              :checked="stati_invio && stati_invio.includes('3')"
              @click="setErroriAffiliazioniRaas()"
            />
            <label class="fw-bold ps-1 text-gray-800"> Errori su invio </label>
          </div>
          <div class="col-sm-2 align-self-center text-center">
            <input
              class="form-check-input"
              type="checkbox"
              :checked="stati_invio && stati_invio.includes('4')"
              @click="setDaReinviareAffiliazioniRaas()"
            />
            <label class="fw-bold ps-1 text-gray-800"> Da reinviare</label>
          </div>
        </div>
        <div class="text-center mt-5">
          <button
            type="button"
            data-bs-toggle=""
            data-bs-target=""
            aria-expanded="false"
            aria-controls=""
            class="badge rounded-pill bg-light text-gray-600"
            @click="$emit('getRaasList')"
            :disabled="!loaded"
          >
            Cerca <i class="bi bi-search fs-6 text-gray-600"> </i>
          </button>
          <span
            type="button"
            data-bs-toggle=""
            data-bs-target=""
            aria-expanded="false"
            aria-controls=""
            class="badge rounded-pill bg-light text-gray-600 ms-4"
            @click.prevent="$emit('resetFilters')"
          >
            <i class="bi bi-arrow-clockwise fs-6 text-gray-600"> </i
          ></span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import "vue3-date-time-picker/dist/main.css";
import { defineComponent, computed, ref, watch, getCurrentInstance } from "vue";
import { useStore } from "vuex";
import SelectInput from "@/components/components-fit/utility/inputs/SelectInput.vue";

export default defineComponent({
  name: "filtri-affiliazioni-raas",
  emits: ["resetFilters", "getRaasList"],
  components: {
    SelectInput,
  },

  setup(props, { emit }) {
    const store = useStore();

    const instance = getCurrentInstance();
    const globalApi =
      instance.appContext.config.globalProperties.$apiListGlobal;

    const comitati = computed(() => store.getters.getStateFromName("comitati"));

    const keys = ref("c");
    if (!comitati.value) {
      store.dispatch("setStoreData", {
        keys: { keys: keys.value },
        apiLink: globalApi.COMBOLIST_GET,
      });
    }

    const stagioneAffiliazioneRaas = computed(
      () => store.getters.stagioneSelected
    );
    watch(stagioneAffiliazioneRaas, () => {
      emit("getRaasList");
    });

    const denominazioneAffiliazioniRaas = computed(
      () => store.getters.denominazione_raas_aff
    );
    const setDenominazioneAffiliazioniRaas = (event) => {
      store.commit("setDenominazione_raas_aff", event);
    };
    const codiceFiscAffiliazioniRaas = computed(
      () => store.getters.codice_fiscale_raas_aff
    );
    const setCodiceFiscAffiliazioniRaas = (event) => {
      store.commit("setCodice_fiscale_raas_aff", event);
    };
    const codiceAffAffiliazioniRaas = computed(
      () => store.getters.codice_affiliazione_raas_aff
    );
    const setCodiceAffAffiliazioniRaas = (event) => {
      store.commit("setCodice_affiliazione_raas_aff", event);
    };
    const comitatoAffiliazioniRaas = computed(
      () => store.getters.id_comitato_raas_aff
    );
    const setComitatoAffiliazioniRaas = (event) => {
      store.commit("setId_comitato_raas_aff", event);
    };
    const daInviareAffiliazioniRaas = computed(
      () => store.getters.stati_invio_raas_aff
    );
    const setDaInviareAffiliazioniRaas = () => {
      store.dispatch("setDaInviareAffiliazioniRaas");
    };
    const stati_invio = computed(() => store.getters.stati_invio_raas_aff);
    const setInviateAffiliazioniRaas = () => {
      store.dispatch("setInviateAffiliazioniRaas");
    };
    // const erroriAffiliazioniRaas = computed(
    //   () => store.getters.erroriAffiliazioniRaas
    // );
    const setErroriAffiliazioniRaas = () => {
      store.dispatch("setErroriAffiliazioniRaas");
    };
    // const daReinviareAffiliazioniRaas = computed(
    //   () => store.getters.daReinviareAffiliazioniRaas
    // );
    const setDaReinviareAffiliazioniRaas = () => {
      store.dispatch("setDaReinviareAffiliazioniRaas");
    };

    return {
      comitati,
      loaded: computed(() =>
        store.getters.getStateFromName("loadedraas_affiliazioni_list")
      ),
      denominazioneAffiliazioniRaas,
      codiceFiscAffiliazioniRaas,
      codiceAffAffiliazioniRaas,
      comitatoAffiliazioniRaas,
      daInviareAffiliazioniRaas,
      stati_invio,
      // inviateAffiliazioniRaas,
      // erroriAffiliazioniRaas,
      // daReinviareAffiliazioniRaas,
      setDenominazioneAffiliazioniRaas,
      setCodiceFiscAffiliazioniRaas,
      setCodiceAffAffiliazioniRaas,
      setComitatoAffiliazioniRaas,
      setDaInviareAffiliazioniRaas,
      setInviateAffiliazioniRaas,
      setErroriAffiliazioniRaas,
      setDaReinviareAffiliazioniRaas,
    };
  },
});
</script>

<style></style>
