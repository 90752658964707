<template>
  <div>
    <FilterAffiliazioniRaas
      @getRaasList="searchRaasList"
      @resetFilters="resetFilters"
    />
    <TableAffiliazioniRaas
      @getRaasList="getRaasList"
      @resetFilters="resetFilters"
    />
  </div>
</template>

<script>
import {
  defineComponent,
  onMounted,
  computed,
  getCurrentInstance,
  watch,
} from "vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import { useStore } from "vuex";
import FilterAffiliazioniRaas from "@/components/components-fit/raas/FiltriAffiliazioniRaas.vue";
import TableAffiliazioniRaas from "@/components/components-fit/raas/TableAffiliazioniRaas.vue";

export default defineComponent({
  name: "affiliazioni-raas",
  components: {
    FilterAffiliazioniRaas,
    TableAffiliazioniRaas,
  },
  props: {},
  setup() {
    onMounted(() => {
      setCurrentPageBreadcrumbs("Raas");
    });
    const store = useStore();

    const instance = getCurrentInstance();
    const globalApi =
      instance.appContext.config.globalProperties.$apiListGlobal;

    const anno = computed(() => store.getters.stagioneSelected);

    const data_ultimo_invio = computed(
      () => store.getters.data_ultimo_invio_raas_aff
    );
    const denominazione = computed(() => store.getters.denominazione_raas_aff);

    const codice_fiscale = computed(
      () => store.getters.codice_fiscale_raas_aff
    );
    const codice_affiliazione = computed(
      () => store.getters.codice_affiliazione_raas_aff
    );
    const id_comitato = computed(() => store.getters.id_comitato_raas_aff);
    const stati_invio = computed(() => store.getters.stati_invio_raas_aff);

    const rowsToSkip = computed(() => store.getters.rowtoskip_raas_aff);
    const fetchRows = computed(() => store.getters.fetchrows_raas_aff);
    const sortColumn = computed(() => store.getters.sortcolumn_raas_aff);
    const sortOrder = computed(() => store.getters.sortorder_raas_aff);

    const getRaasList = () => {
      const localKeys = {
        anno: anno.value,
        data_ultimo_invio: data_ultimo_invio.value,
        denominazione: denominazione.value,
        codice_fiscale: codice_fiscale.value,
        codice_affiliazione: codice_affiliazione.value,
        id_comitato: id_comitato.value,
        stati_invio: stati_invio.value,
        rowtoskip: rowsToSkip.value,
        fetchrows: fetchRows.value,
        sortcolumn: sortColumn.value,
        sortorder: sortOrder.value,
      };
      store.dispatch("setStoreListData", {
        keys: localKeys,
        apiLink: globalApi.RAAS_AFFILIAZIONI,
        itemName: "raas_affiliazioni_list",
      });
    };

    const resetFilters = () => {
      store.commit("resetFiltersRaasAff");
      getRaasList();
    };
    getRaasList();

    const loaded = computed(() =>
      store.getters.getStateFromName("loadedraas_affiliazioni_list")
    );
    const searchRaasList = () => {
      if (!loaded.value) return;
      store.commit("setRowsToSkipRaasAff");
      getRaasList();
    };

    watch(anno, () => {
      searchRaasList();
    });

    return {
      getRaasList,
      resetFilters,
      searchRaasList,
    };
  },
});
</script>
